import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/menu',
    name: 'home',
    component: function () {
      return import( '../views/menu.vue')
    }
  },

  { // 配置默认路由
    path: "/", // 路由地址
    redirect: "/menu" // 重定向
  },

  {
    path: '/m',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/index.vue')
    }
  },
  
  {
    path: '/tj/cjxx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/cjxx.vue')
    }
  },
  
  {
    path: '/tj/tjzx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/tjzx.vue')
    }
  },
  
  {
    path: '/tj/xgtjzy',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/xgtjzy.vue')
    }
  },
  {
    path: '/tj/admissionNotice', 
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/admissionNotice.vue')
    }
  },
  
  {
    path: '/tj/tjyx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/tjyx.vue')
    }
  },
  {
    path: '/tj/retest',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/retest.vue')
    }
  },
  {
    path: '/tj/yzytz',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/yzytz.vue')
    }
  },
  {
    path: '/tj/qecx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/qecx.vue')
    }
  },
  
  {
    path: '/tj/writer',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/writer.vue')
    }
  },
  
  {
    path: '/tj/tjzy',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tj/tjzy.vue')
    }
  },

  {
    path: '/m/tjyx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/tjyx.vue')
    }
  },
  {
    path: '/m/tjyx/qecx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/qecx.vue')
    }
  },
  {
    path: '/m/tjyx/writer',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/writer.vue')
    }
  },
  {
    path: '/m/tjyx/tjzy',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/tjzy.vue')
    }
  },
  {
    path: '/m/tjyx/cjxx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/m/cjxx.vue')
    }
  },
  
  {
    path: '/tjyx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/index.vue')
    }
  },
  {
    path: '/tjyx/qecx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/qecx.vue')
    }
  },
  
  {
    path: '/tjyx/writer',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/writer.vue')
    }
  },
  
  {
    path: '/tjyx/success',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/success.vue')
    }
  },
  {
    path: '/tjyx/tjzy',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/tjzy.vue')
    }
  },
  {
    path: '/tjyx/cjxx',
    name: '全国硕士生招生调剂意向采集系统',
    component: function () {
      return import( '../views/tjyx/cjxx.vue')
    }
  },
  {
    path: '/AIBalanceQuery',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/HomeView.vue')
    }
  },
  
  {
    path: '/admissionNotice',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/admissionNotice.vue')
    }
  },
  
  {
    path: '/tjzx',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/tjzx.vue')
    }
  },
  
  {
    path: '/xgtjzy',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/xgtjzy.vue')
    }
  },
  
  {
    path: '/login',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/login.vue')
    }
  },
  {
    path: '/firstTest',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/firstTest.vue')
    }
  },
  
  {
    path: '/firstTest',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/firstTest.vue')
    }
  },
  {
    path: '/yzytz',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/yzytz.vue')
    }
  },
  {
    path: '/about',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/AboutView.vue')
    }
  },
  {
    path: '/about/writer',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/writer.vue')
    }
  },
  {
    path: '/about/success',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/success.vue')
    }
  },
  {
    path: '/retest',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/retest.vue')
    }
  },
  {
    path: '/home',
    name: '模拟调剂系统',
    component: function () {
      return import( '../views/home.vue')
    }
  }
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  console.log("beforeEach", { to, from, next })
  next()
})

export default router
