const { default: router } = require("@/router")

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
const getTimeStap = (date) => {
  const strDate = date.replace(/-/g, '/').replace(/T/g, ' ').replace(/(\.|\+)[\d]{3,4}/g, '')
  const tempDate = new Date(strDate);
  tempDate.setHours(tempDate.getHours() + 8)
  return tempDate.getTime()
}
var dateDiff = function (timestamp) {
  // 补全为13位
  var arrTimestamp = (timestamp + '').split('');
  for (var start = 0; start < 13; start++) {
    if (!arrTimestamp[start]) {
      arrTimestamp[start] = '0';
    }
  }
  timestamp = arrTimestamp.join('') * 1;
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date().getTime();
  var diffValue = now - timestamp;
  // 如果本地时间反而小于变量时间
  if (diffValue < 0) {
    return '刚刚';
  }
  // 计算差异时间的量级
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;

  // 数值补0方法
  var zero = function (value) {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  };

  // 使用
  if (monthC > 12) {
    // 超过1年，直接显示年月日
    return (function () {
      var date = new Date(timestamp);
      return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
    })();
  } else if (monthC >= 1) {
    return parseInt(monthC) + "月前";
  } else if (weekC >= 1) {
    return parseInt(weekC) + "周前";
  } else if (dayC >= 1) {
    return parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    return parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    return parseInt(minC) + "分钟前";
  }
  return '刚刚';
};
const getSize = function (images, totalw, mr) {
  const {
    screenWidth
  } = wx.getSystemInfoSync();
  if (!images) return;
  if (images.length == 1) {
    return {
      w: screenWidth,
      h: screenWidth,
    }
  } else if (images.length == 2) {
    const imgw = (totalw - mr) / 2
    return {
      w: imgw,
      h: imgw
    }
  } else {
    const imgw = (totalw - mr * 2) / 3
    return {
      w: imgw,
      h: imgw
    }
  }
}
const formatTime = (date, format) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  const tempDate = [year, month, day].map(formatNumber),
    tempTime = [hour, minute].map(formatNumber)
  switch (format) {
    case 'YYYY-MM-DD':
    default:
      return tempDate.join('-')
      break
    case 'YYYY/MM/DD':
      return tempDate.join('/')
      break
    case 'YYYY.MM.DD':
      return tempDate.join('.')
      break
    case 'YYYY-MM-DD HH:mm':
      return tempDate.join('-') + ' ' + tempTime.join(':')
      break
    case 'YYYY-MM-DD HH:mm:ss':
      return tempDate.join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
      break
    case 'MM月DD日':
      return month + '月' + day + '日'
      break
    case 'MM-DD':
      return month + '-' + day
      break
    case 'HH:mm':
      return [hour, minute].map(formatNumber).join(':')
      break
    case 'MM月DD日 HH:mm':
      return month + '月' + day + '日' + [hour, minute].map(formatNumber).join(':')
      break
    case 'YYYY年MM月DD日 HH:mm':
      return year + '年' + month + '月' + day + '日' + [hour, minute].map(formatNumber).join(':')
      break
  }
}
// 格式化时间,类似于这种2019-05-09T05:28:06.000+0000
const formatTime1 = (date, format) => {
  const strDate = date
    .replace(/-/g, '/')
    .replace(/T/g, ' ')
    .replace(/(\.|\+)[\d]{3,4}/g, '')
  const tempDate = new Date(strDate)
  tempDate.setHours(tempDate.getHours() + 8)
  return formatTime(tempDate, format)
}
let toChinesNum = (num) => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
    }
    return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}
let browserRedirect = ()=>{

    var sUserAgent = navigator.userAgent.toLowerCase();
 
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
 
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
 
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
 
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
 
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
 
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
 
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
 
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
 
    if (
 
      bIsIpad ||
 
      bIsIphoneOs ||
 
      bIsMidp ||
 
      bIsUc7 ||
 
      bIsUc ||
 
      bIsAndroid ||
 
      bIsCE ||
 
      bIsWM
 
    ) {
      // 手机浏览器
      console.log("phone");
      return "phone"
 
    } else {
      // PC端浏览器
      console.log("pc");
      return "pc"
 
    }
}

module.exports = {
  formatTime: formatTime,
  getTimeStap: getTimeStap,
  dateDiff: dateDiff,
  getSize: getSize,
  toChinesNum,
  formatTime1,
  browserRedirect,
}