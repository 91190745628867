import axios from 'axios'
import router from '@/router';
var api = ""
// 判断是非为本地测试环境打开 http://10.20.37.33/
if(document.domain == "192.168.1.41" || document.domain == "localhost" ){
	// api = `https://dev.kyapp.xiaobaikaoyan.com`;
	// api = `https://api.app.xiaobaitech.cn`;
	api = `http://39.100.226.85:8416`;
	// http://39.100.226.85:8416
	// api = `https://tiaoji.yanxiaobai.com`
}

axios.defaults.timeout = 60000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json';        
axios.defaults.headers.get['Content-Type'] = 'application/json';        

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
}); 

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
	console.log("res",res)
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
	const { status } = error.response;
	if (status === 401) {
		router.push('/login');
	}
    console.log('网络异常',error)
    return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
	return new Promise((resolve, reject) => {
	    axios.post(api+url, params,)
	        .then(response => {
	            resolve(response);
	        }, err => {
	            reject(err);
	        })
	        .catch((error) => {
	            reject(error)
	        })
	})
}
export function fetchPostToken(url, params) {
	var storage = window.sessionStorage;
	var token = storage.getItem('token')
	
	return new Promise((resolve, reject) => {
	    axios.post(api+url,params,{headers: {'Authorization': 'Bearer ' + token}})
	        .then(response => {
	            resolve(response);
	        }, err => {
	            reject(err);
	        })
	        .catch((error) => {
	            reject(error)
	        })
	})
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param, headers) {
    return new Promise((resolve, reject) => {
        axios.get(api+url, {
				params: param,
				headers: headers
			})
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function fetchGetToken(url, param) {
	var storage = window.sessionStorage;
	var token = storage.getItem('token')
	if( !/^\d+$/.test(token)){
		return new Promise((resolve, reject) => {
				axios.get(api+url, {headers: {
					'Authorization': 'Bearer ' + token
					},
				params: param
				})
		        .then(response => {
		            resolve(response)
		        }, err => {
		            reject(err)
		        })
		        .catch((error) => {
		            reject(error)
		        })
		})
	}else{
		return new Promise((resolve, reject) => {
				axios.get(api+url, {
				params: param
				})
		        .then(response => {
		            resolve(response)
		        }, err => {
		            reject(err)
		        })
		        .catch((error) => {
		            reject(error)
		        })
		})
	}
    
}

export default {
	
    fetchGet,
	fetchGetToken,
	
	fetchPost,
	fetchPostToken,
	api,
}