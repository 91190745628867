<template>
	<div id="app">
		<template v-if="platform == 'pc' && !isLogin">
			<header>
				<router-link to="/menu">
					<a class="name">
						<img src="./assets/logo.png" alt="">
						{{ isTjyx ? '全国硕士生招生调剂意向采集系统' : '全国硕士生招生模拟调剂系统' }}
					</a>
				</router-link>
				<div class="function">
					<div class="user">
						<div>{{ currentUse.nickname }}</div>
						<!-- <div>考研上岸</div> -->
					</div>
					<!-- <div class="user">
          <div>咆哮</div>
          <div>退出</div>
        </div> -->
					<!-- <div class="help" data-v-43cb4a0c=""><a href="http://tiaoji.feitent.com/" class="" data-v-43cb4a0c="">研招网首页</a><a href="http://tiaoji.feitent.com/" class="" data-v-43cb4a0c="">帮助中心</a></div> -->
				</div>
			</header>
			<template v-if="isTjyx">
				<nav v-if="isNav">
					<div class="menu">
						<router-link to="/tjyx">首页</router-link>
						<el-divider direction="vertical"></el-divider>
						<div class="menu_vi">意向</div>
						<router-link to="/tjyx/qecx">调剂意向余额查询</router-link>
						<router-link to="/tjyx/tjzy">调剂意向</router-link>
						<el-divider direction="vertical"></el-divider>
						<router-link to="/tjyx/cjxx">初试成绩</router-link>
					</div>
				</nav>
				<div class="line" v-else></div>
			</template>
			<template v-else>
				<nav v-if="isNav">
					<div class="menu">
						<router-link to="/home">首页</router-link>
						<el-divider direction="vertical"></el-divider>
						<router-link to="/yzytz">一志愿复试待录取通知</router-link>
						<el-divider direction="vertical"></el-divider>
						<div class="menu_vi">意向</div>
						<router-link to="/AIBalanceQuery">计划余额查询</router-link>
						<!-- <router-link to="/AIBalanceQuery">申请调剂</router-link> -->
						<router-link to="/about">申请调剂</router-link>

						<!-- <router-link to="/AIBalanceQuery">调剂意向余额查询</router-link> -->
						<!-- <router-link to="/about">调剂意向</router-link> -->
						<router-link to="/retest">复试通知</router-link>
						<router-link to="/admissionNotice">待录取通知</router-link>
						<router-link to="/firstTest">初试成绩</router-link>
					</div>
				</nav>
				<div class="line" v-else></div>
			</template>
		</template>
		<router-view />
		<footer v-if="platform == 'pc' && !isLogin"><span>版权说明</span></footer>
	</div>
</template>
<script>
	import https from './https'
	export default {
		data() {
			return {
				isTjyx: false,
				isLogin:false,
				isNav: true,
				query: {},
				currentUse: {},
				platform: "",
			}
		},
		watch: {
			// 监听路由 
			$route(to, from) {
				console.log(from.path); //从哪来 
				console.log(to.path); //到哪去
				if (to.path.indexOf("login") >= 0) {
					this.isLogin = true
				} else {
					this.isLogin = false
				}
				if (to.path.indexOf("tjyx") >= 0) {
					this.isTjyx = true
				} else {
					this.isTjyx = false
				}
				if (to.path == "/menu") {
					this.updateUser()
					this.isNav = false
				} else {
					this.isNav = true
				}
			}
		},
		created() {
			let platform = this.util.browserRedirect();
			this.platform = platform;



			setTimeout(() => {
				let query = this.$route.query;
				this.query = query;
				console.log("this.$route.token", this.query.token)
				this.storage = window.sessionStorage;
				if (this.query.token) {
					this.storage.setItem("token", this.query.token);
				}
				if (this.storage.getItem("token")) {
					this.updateUser()
				}
				if (this.$route.path.indexOf("tjyx") >= 0) {
					this.isTjyx = true
				} else {
					this.isTjyx = false
				}
			}, 100)
			// this.util
			// console.log("util", this.util.browserRedirect(),this.$route.path)
			// let platform = this.util.browserRedirect();
			// this.platform = platform;

			// if (this.$route.path.indexOf("menu") != -1 || this.$route.path == '/') {e
			// }else{
			//   if (platform == "phone") {
			//     this.$router.push({
			//       path: '/m',
			//     })
			//   } else {
			//     this.$router.push({
			//       path: '/tjyx',
			//     })
			//   }
			// }
		},
		methods: {
			updateUser() {
				https.fetchGetToken("/api/user/currentUser").then(res => {
					console.log(res.data.data)
					if (res.data.code == 200) {
						this.currentUse = res.data.data;
						let currentUse = res.data.data;
						this.storage.setItem("currentUse", res.data.data);
					}
				}).catch((err) => {
					this.loading = false
					this.$notify.error({
						title: '错误',
						message: err.msg || '访问人数过多请稍后再试'
					});
					console.log(err);
				});
			}
		}
	}
</script>
<style>
	body {
		margin: 0;
		color: #000000d9;
		font-size: 14px;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
		font-variant: tabular-nums;
		line-height: 1.5715;
		background-color: #fff;
		font-feature-settings: "tnum";


	}

	a:active {
		color: #0f68bb;
	}

	a {
		color: #1887e0;
		background: transparent;
		text-decoration: none;
		outline: none;
		cursor: pointer;
		transition: color .2s ease;
	}

	.el-main {
		width: 1220px;
		margin: 34px auto;
		width: 1360px;
		height: auto;
		min-height: 50vh;
		padding: 0 !important;
	}
</style>
<style scoped>
	.menu_vi {
		display: flex;
		align-items: center;
		height: 100%;
		margin-left: 36px;
		margin-right: 10px;
		opacity: .85;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
	}

	footer span {
		margin-right: 40px;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: #999;
	}

	footer {
		position: sticky;
		bottom: 0;
		text-align: center;
		height: 54px;
		/* margin-top: 60px; */
		background-color: #fff;
		border-top: 1px solid #efefef;
		line-height: 54px;
		z-index: 100;
	}

	.line {
		width: 100%;
		height: 3px;
		background-color: #1174c3;
	}

	nav {
		width: 100%;
		min-width: 1180px;
		box-sizing: border-box;
		height: 55px;
		background: #1887e0;
	}

	.menu {
		width: 1180px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		padding: 0 20px;
		box-sizing: border-box;
	}

	nav .active {
		background: #0A95CD;
		transition: .2s all ease-in-out
	}

	header {
		width: 1270px;
		height: 122px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
	}

	header .name {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 30px;
		line-height: 41px;
		color: #000;
	}

	header .name>img {
		width: 62px;
		height: 30px;
		margin-right: 4px;
	}

	header .function {
		font-size: 16px;
	}

	header .function .user {
		text-align: right;
	}

	header .function .user>div {
		margin-left: 16px;
		line-height: 22px;
		color: #666;
		display: inline-block;
		cursor: pointer;
	}

	nav a {
		color: #fff;
		font-size: 18px;
		line-height: 55px;
		padding: 0 22px;
		cursor: pointer;
		position: relative;
		text-decoration: none;
	}

	nav a.router-link-exact-active {
		background: #1174c3;
		transition: .2s all ease-in-out;
	}
</style>